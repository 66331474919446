$breakpoint-mobile-sm: 575.98px;
$breakpoint-mobile: 767.98px;
$breakpoint-tablet: 991.98px;
$breakpoint-desktop: 1199.98px;
$breakpoint-main-container: 1066px;

// mixins
@mixin dynamicTextSize($baseWidth, $baseFontSize: 16px, $minWidth: 320px) {
  @media (max-width: $baseWidth) and (min-width: $minWidth) {
    font-size: $baseFontSize / $baseWidth * 100vw;
  }
}
