@import "src/themes/__variables.scss";

:local {
    .info {
        padding: 2em 2em 2.8em 2em;
        background-color: #ffffff;
        text-align: center;
        font-family: Oxygen, sans-serif;

        h1 {
            font-size: 1.72em;
            color: #233971;
            font-weight: lighter;
            margin-top: 0;
            margin-bottom: 1em;
            font-weight: bold;
        }

        p {
            color: #97A4AA;
            margin: 0 auto;
            line-height: 1.72;
            max-width: 650px;
            margin: 0 auto;
        }
    }

    .itemsArea {
        max-width: 500px;
        margin: 0 auto;
    }

    .btn {
        margin-top: 40px;
    }

    .body {
        background-color: var(--bg-color);
        font-family: Oxygen, sans-serif;

        text-align: center;
        padding: 3.75em 1.15em 4.375em 1.15em;
		font-family: Oxygen, sans-serif;

        h2 {
            color: #233971;
			margin: 0 0 2.5em 0;
            font-weight: normal;
        }
    }
}
